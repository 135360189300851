import styled from 'styled-components';
import { UserInteraction } from '@naf/user-interaction';
import { breakpoints, spacing } from '@naf/theme';

const StyledUserInteraction = styled(UserInteraction)`
  max-width: 496px;
  margin-bottom: ${spacing.space24};
  margin-top: ${spacing.space48};

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
    max-width: calc(100% - ${spacing.space48});
    margin-top: ${spacing.space24};
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints.s}) {
    width: 100%;
    max-width: calc(100% - ${spacing.space32});
  }
`;

export default { StyledUserInteraction };
